import { IPublicClientApplication } from "@azure/msal-browser";
import { IRide } from "../models/IRide";
import { IEventAndRides } from "../models/IEventAndRides";
import { AccessTokenBasedService } from "./AccessTokenBasedService";

export class RidesService extends AccessTokenBasedService {

  public static refreshCallbacks: ((data: IEventAndRides[]) => void)[] = [];


  constructor(public msalInstance: IPublicClientApplication, private teamId: string) {
    super(msalInstance)
  }

  async applyAsDriver(teamId: string, eventId: string, ride: IRide): Promise<void> {
    try {
      const client = await this.getClient();
      const result = await client.post(`/teams/${teamId}/events/${eventId}/ApplyAsDriver`, ride);
      this.handleError(result);
    } catch (error) {
      console.error('Error while trying to add a new ride');
      throw error;
    }
  }

  async bookRide(teamId: string, eventId: string, rideId: string, passengerIds: string[], comments?: string): Promise<void> {
    try {
      const client = await this.getClient();
      const result = await client.post(`/teams/${teamId}/events/${eventId}/rides/${rideId}/Book`, { passengerIds, comments });
      this.handleError(result);
    } catch (error) {
      console.error('Error while trying to book passengers on a ride');
      throw error;
    }
  }
}