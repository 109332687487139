import { useCallback, useMemo } from "react";
import { useTeamContext } from "../../contexts/TeamContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { Welcome } from "../Welcome/Welcome";
import { NavBar } from "../Navbar/NavBar";
import { Notifications } from "../Notifications/Notifications";
import balanceIcon from '../../assets/images/balance-icon.png';
import { useEventContext } from "../../contexts/EventContext";
import { Avatar, Stack } from "@mui/material";
import { getFriendlyDate } from "../../helpers/date-helpers";
import { trimTo } from "../../helpers/strings";
import { Groups2 } from "@mui/icons-material";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const route = useMemo(() => location.pathname.toLowerCase(), [location]);
    const isEventsListRoute = useMemo(() => route.startsWith('/team') && route.endsWith('/events'), [route]);
    const isMainRoute = useMemo(() => {
        switch (route) {
            case '/onboarding':
            case '/admin':
            case '/profile':
            case '/select-team':
            case '/my-rides':
            case '/my-team':
            case '/':
                return true;
            default:
                if (isEventsListRoute)
                    return true;
                else
                    return false;
        }
    }, [route, isEventsListRoute]);
    const { selectTeam, user } = useUserContext();
    const { teamName, team, teamId } = useTeamContext();
    const { event } = useEventContext();
    const balance = useMemo(() => {
        if (!teamId || !user?.teamMemberships || Object.keys(user.teamMemberships).length === 0)
            return 0;

        return user.teamMemberships[teamId]?.balance;
    }, [user, teamId]);

    const goToTeamSelection = useCallback(() => {
        selectTeam(null);
        navigate('/select-team');
    }, [selectTeam, navigate]);

    const headerContent = useMemo(() => {

        let content: JSX.Element = null;

        if (!isMainRoute) {
            if (!!event) {
                if (route.endsWith('/book')) {
                    content = <>
                        {trimTo(event.name, 28)} - {getFriendlyDate(event.startTime)}<br />
                        Réserver un Ride
                    </>;
                } else if (route.endsWith('/applyasdriver')) {
                    content = <>
                        {trimTo(event.name, 28)} - {getFriendlyDate(event.startTime)} <br />
                        Proposer un Ride
                    </>;

                } else {
                    content = <>
                        Evênement<br />
                        {trimTo(event.name, 28)} - {getFriendlyDate(event.startTime)}
                    </>;
                }
            } else if (route.endsWith('/new-event')) {
                content = !!teamName ? <>
                    {teamName}<br/>
                    Nouvel évènement<br />
                </> : <span style={{ fontSize: '1.1rem' }}>Nouvel évènement</span>;
            }
        } else {
            switch (route) {
                case '/profile':
                    content = <span style={{ fontSize: '1.1rem' }}>Profil</span>;
                    break;
                case '/my-rides':
                    content = <span style={{ fontSize: '1.1rem' }}>Rides</span>;
                    break;
                case '/my-team':
                    content = !!teamName ? <>
                        Equipe<br />
                        {teamName}
                    </> : <span style={{ fontSize: '1.1rem' }}>Equipe</span>;
                    break;
            }

            if (isEventsListRoute) {
                content = <span style={{ fontSize: '1.1rem' }}>
                    Calendrier
                </span>
            }
        }

        return <>
            <Stack direction={'row'} gap={2} spacing={2} alignItems={'center'} lineHeight={'1.5'}>
                {!isMainRoute
                    ? <svg onClick={() => navigate(-1)} className="BackButton" width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0742 20.2559L0.318359 10.5L10.0742 0.744141L11.2559 1.92578L2.68164 10.5L11.2559 19.0742L10.0742 20.2559Z" fill="white" />
                    </svg>
                    : <span onClick={() => goToTeamSelection()}>
                        <Avatar sx={{ width: '32px', height: '32px', margin: 0 }} src={team?.avatar} >
                            <Groups2 />
                        </Avatar>
                    </span>
                }
                {content}
            </Stack>
        </>;

    }, [teamName, team, route, isMainRoute, isEventsListRoute, event, goToTeamSelection, navigate]);

    if (!isAuthenticated)
        return null;

    return <header className="AppHeader">
        <div onClick={() => goToTeamSelection()}>
            {headerContent}
        </div>
        <div className="Quota">
            <img className="QuotaCar" src={balanceIcon} alt="Car Quota" />
            {balance} X
        </div>
        <div className="Notification">
            <Notifications />
        </div>
    </header>;
};

const Footer = () => {
    const isAuthenticated = useIsAuthenticated();
    if (!isAuthenticated)
        return null;

    return <footer className="AppFooter">
        <NavBar />
    </footer>;
}

export const AppLayout = () => {
    return <div className="AppLayout">
        <Header />
        <div style={{ minHeight: '10vh' }} className="spacing"></div>
        <AuthenticatedTemplate>
            <Outlet />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Welcome />
        </UnauthenticatedTemplate>
        <div style={{ minHeight: '10vh' }} className="spacing"></div>
        <Footer />
    </div>
};