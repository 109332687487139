import { useCallback, useEffect, useMemo, useState } from "react"
import { IEventAndRides } from "../../models/IEventAndRides"
import { EventCard } from "../EventCard/EventCard";
import { addMonths } from "date-fns";
import { useTeamContext } from "../../contexts/TeamContext";
import './EventsList.css';
import { MonthSelector } from "../MonthSelector/MonthSelector";
import { IMonthAndYear } from "../../models/IMonthAndYear";
import { Loading } from "../Loading/Loading";
import { IconButton, Slide, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";

export interface IEventCardsGroupProps {
  period: IMonthAndYear;
  events: IEventAndRides[];
  selectedPeriod: boolean;
  onPeriodChanged: (period: IMonthAndYear) => void;
}

export function EventCardsGroup({ events, period,
  // selectedPeriod, onPeriodChanged
}: IEventCardsGroupProps) {

  // const ref = useRef();
  // const observer = useMemo(() => new IntersectionObserver((entries, observer) => {
  //   for (let entry of entries) {
  //     if (entry.isIntersecting && !selectedPeriod && entry.intersectionRatio > 0.2) {
  //       // if (entry.isIntersecting) {
  //       console.log('Intersection ratio with period', entry.intersectionRatio, period);
  //       onPeriodChanged(period);
  //       observer.unobserve(entry.target);
  //     }
  //   }
  // }), [period, selectedPeriod, onPeriodChanged]);

  // useEffect(() => {
  //   if (ref.current && !selectedPeriod) {
  //     observer.observe(ref.current);
  //     // const elementRef = ref.current;
  //     // return () => {
  //     //   observer.unobserve(elementRef);
  //     // };
  //   }
  // }, [observer, ref, selectedPeriod]);

  return <div id={`period_${period.year}_${period.month}`}>
    {/* <h2 className="Period">{getMonthPeriodFriendly(periodFirstDay)}</h2> */}
    {!events || events.length === 0
      ? <span className="NoEvent">Aucun événement disponible</span>
      : events.map(ev => <EventCard eventData={ev} />)}
  </div>;
}

export function EventsList() {
  const { isLoading, eventsAndRides, currentUserIsAdmin, teamId } = useTeamContext();
  const [selectedPeriod, setSelectedPeriod] = useState<IMonthAndYear>({
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  });
  const navigate = useNavigate();
  const sortedEvent = useMemo(() => eventsAndRides?.sort((a: IEventAndRides, b: IEventAndRides) => (+new Date(a.event.startTime)) - (+new Date(b.event.startTime))), [eventsAndRides]);

  const firstAvailablePeriod = useMemo(() => {
    const firstEvent = (sortedEvent?.length || 0) > 0 ? sortedEvent[0] : null;
    if (!firstEvent)
      return null;
    const firstEventDate = new Date(firstEvent.event.startTime);
    return {
      month: firstEventDate.getMonth(),
      year: firstEventDate.getFullYear()
    } as IMonthAndYear;
  }, [sortedEvent]);

  const lastAvailablePeriod = useMemo(() => {
    const lastEvent = (sortedEvent?.length || 0) > 0 ? sortedEvent[sortedEvent.length - 1] : null;
    if (!lastEvent)
      return null;
    const lastEventDate = new Date(lastEvent.event.startTime);
    return {
      month: lastEventDate.getMonth(),
      year: lastEventDate.getFullYear()
    } as IMonthAndYear;
  }, [sortedEvent]);

  const allPeriodFirstDays = useMemo(() => {
    if (!firstAvailablePeriod || !lastAvailablePeriod)
      return [];

    const firstPeriodFirstDay = new Date(firstAvailablePeriod.year, firstAvailablePeriod.month, 1);
    const lastPeriodFirstDay = new Date(lastAvailablePeriod.year, lastAvailablePeriod.month, 1);
    const firstDays: Date[] = [];
    let d: Date = firstPeriodFirstDay;
    while (d <= lastPeriodFirstDay) {
      firstDays.push(d);
      d = addMonths(d, 1);
    }
    return firstDays;
  }, [firstAvailablePeriod, lastAvailablePeriod]);

  const allPeriods = useMemo(() => {
    return allPeriodFirstDays.map(d => ({ year: d.getFullYear(), month: d.getMonth() }));
  }, [allPeriodFirstDays]);

  const getEventsInPeriod = useCallback((period: IMonthAndYear) => {
    const filteredEvents = eventsAndRides.filter(ev => {
      const evDate = new Date(ev.event.startTime);
      return evDate.getMonth() === period.month && evDate.getFullYear() === period.year;
    });
    return filteredEvents;
  }, [eventsAndRides]);

  const scrollToPeriod = useCallback((period: IMonthAndYear) => {
    const elementId = `period_${period.year}_${period.month}`;
    const el = document.getElementById(elementId);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (selectedPeriod)
      scrollToPeriod(selectedPeriod);
    else if (eventsAndRides && eventsAndRides.length > 0) {
      scrollToPeriod({ year: new Date().getFullYear(), month: new Date().getMonth() });
    }
  }, [eventsAndRides, scrollToPeriod, selectedPeriod]);

  if (isLoading) {
    return <Loading />;
  }

  return <Slide in direction='down'>
    <div className="EventsList">
      <Stack direction='row' gap={4} spacing={2} alignContent={'center'} alignItems={'center'} justifyContent={'space-between'} justifyItems={'cemter'}>
        <MonthSelector
          minPeriod={firstAvailablePeriod}
          maxPeriod={lastAvailablePeriod}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod} />
        {currentUserIsAdmin && <IconButton aria-label="delete" onClick={() => navigate(`/team/${teamId}/new-event`)}>
          <AddIcon />
        </IconButton>}
      </Stack>
      <div className="CardsContainer">
        {allPeriods.map(p => <EventCardsGroup events={getEventsInPeriod(p)}
          selectedPeriod={selectedPeriod.year === p.year && selectedPeriod.month === p.month}
          period={p}
          onPeriodChanged={setSelectedPeriod} />)}
          {allPeriods.length === 0 ? <div className="NoEvent">Aucun évènement disponible...</div> : null}
      </div>
    </div>
  </Slide>;
}