import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from "@mui/material/styles";

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `0px solid ${theme.palette.divider} `,

    borderColor: 'rgba(123, 5, 250, 0.1)',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));