import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ color: 'rgba(123, 5, 250)' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(123, 5, 250, 0.1)',
    borderBottom: `1px solid ${theme.palette.divider}`,
    border: 0,
    borderRadius: '10px',
    marginBottom: '0.5rem',
    minHeight: 56,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        margin: '0',
    },
}));