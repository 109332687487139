import { IPublicClientApplication } from "@azure/msal-browser";
import { IUser } from "../models/IUser";
import { AccessTokenBasedService } from "./AccessTokenBasedService";
import { ITeamMembership } from "../models/ITeamMembership";
import { IPassenger } from "../models/IPassenger";
import { IRide } from "../models/IRide";

export class UserService extends AccessTokenBasedService {

    constructor(public msalInstance: IPublicClientApplication) {
        super(msalInstance);
    }



    async getCurrentUserData(): Promise<IUser> {
        try {
            const client = await this.getClient();
            const result = await client.get(`/my/info`);
            this.handleError(result);
            return result as IUser;
        } catch (error) {
            console.error('Error while trying to get current user info');
            return null;
        }
    }


    async addPassengersToCurrentUser(passengers: IPassenger[]): Promise<IPassenger[]> {
        try {
            const client = await this.getClient();
            const result = await client.patch(`/my/passengers`, { passengers });
            this.handleError(result);
            return result as IPassenger[];
        } catch (error) {
            console.error('Error while trying to add passengers to current user');
            return null;
        }
    }

    async updateCurrentUserInfo(info: IUser): Promise<void> {
        try {
            // Ensure remove useless property from payload
            info = {
                firstName: info.firstName,
                lastName: info.lastName,
                avatar: info.avatar,
                emailAddress: '',
                displayName: ''
            } as IUser;
            const client = await this.getClient();
            const result = await client.put(`/my/info`, info);
            this.handleError(result);
        } catch (error) {
            console.error('Error while trying to update current user info');
            return null;
        }
    }

    async onboard(): Promise<IUser> {
        try {
            const client = await this.getClient();
            const result = await client.post<any, IUser>(`/my/onboarding`, {});
            this.handleError(result);
            return result as IUser;
        } catch (error) {
            console.error('Error while trying to onboard new user');
            throw new Error("Could not add  onboard new user");
        }
    }

    async requestJoinTeam(code: string, comments?: string): Promise<void> {
        try {
            const client = await this.getClient();
            const result = await client.post<{ code: string, comments?: string }, unknown>(`/teams/Join`, { code, comments });
            this.handleError(result);
        } catch (error) {
            console.error('Error while trying to join the team');
            throw new Error("Could not request to join the team");
        }
    }

    async getMyTeams(): Promise<ITeamMembership[]> {
        try {
            const client = await this.getClient();
            const result = await client.get(`/my/teams`);
            this.handleError(result);
            return result as ITeamMembership[];
        } catch (error) {
            console.error('Error while trying to get my teams');
            return [];
        }
    }

    async getMyRides(): Promise<IRide[]> {
        try {
            const client = await this.getClient();
            const result = await client.get(`/my/rides`);
            this.handleError(result);
            return result as IRide[];
        } catch (error) {
            console.error('Error while trying to get my rides');
            return [];
        }
    }

    async getMyPassengers(): Promise<IPassenger[]> {
        try {
            const client = await this.getClient();
            const result = await client.get(`/my/passengers`);
            this.handleError(result);
            return result as IPassenger[];
        } catch (error) {
            console.error('Error while trying to get my passengers');
            return [];
        }
    }
}