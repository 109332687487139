import { useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useTeamContext } from "../../contexts/TeamContext";
import { Button, Stack, Card, CardActionArea, CardContent, Typography, Avatar } from "@mui/material";
import { IRide } from "../../models/IRide";
import { getTime } from "../../helpers/date-helpers";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { DirectionsCar, StartRounded } from "@mui/icons-material";
import './EventDetails.css';

export interface IRideProps {
  ride: IRide;
}

export const Ride = ({ ride }: IRideProps) => {

  return <div className='RideCard' key={ride.id}>

    <Typography variant="subtitle1">
    </Typography>

    <Stack direction='row' justifyContent='space-between' alignContent={'center'} spacing={2} alignItems='center'>
      <Typography variant="subtitle1">
        <Stack direction='row' spacing={1} justifyContent={'center'} alignItems='center' gap={0.5}>
          <DirectionsCar />
          {ride.name}
        </Stack>
      </Typography>
      <Stack direction='row' spacing={-1} justifyContent='left' alignContent='center' alignItems='center'>
        {Array.from({ length: ride.availableSeats }).map((_, index) => (
          <span key={index} title={ride.bookings ? ride.bookings[index]?.name : ''}>
            <Avatar sx={{ height: '25px', width: '25px' }} src={ride.bookings[index]?.avatar} />
          </span>
        ))}
      </Stack>
    </Stack>

    <div className="meetingPoint">
      <Stack direction='column' spacing={0}>
        <div style={{ backgroundColor: 'rgba(123, 5, 250, 0.1)', borderRadius: '10px', color: 'black', padding: '0.5rem' }}>
          <div>
            <Stack direction='row' spacing={1} alignItems='center' lineHeight={3}>
              <div className="rideDirection">
                <Stack direction='column'>
                  <span className="rideDirectionLabel">Départ</span>
                  <StartRounded sx={{ color: 'rgba(123, 5, 250)' }} />
                </Stack>
              </div>
              <Stack direction="column">
                <Stack direction='row' spacing={1} alignItems='center'>
                  <AccessTimeOutlinedIcon />
                  <div>
                    {getTime(ride.startMeetingTime)}
                  </div>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <div>
                    <LocationOnIcon />
                  </div>
                  <div>
                    {ride.startMeetingPoint?.address}
                  </div>
                </Stack>
              </Stack>
            </Stack>
          </div>
        </div>
      </Stack>
    </div>
    <div className="meetingPoint">
      <Stack direction='column' justifyContent='left' spacing={1}>
        <div>
          <Stack direction='column' spacing={0}>
            <div style={{ backgroundColor: 'rgba(123, 5, 250, 0.1)', borderRadius: '10px', color: 'black', padding: '0.5rem' }}>
              <div>
                <Stack direction='row' spacing={1} alignItems='center' lineHeight={3}>
                  <div className="rideDirection">
                    <Stack direction='column'>
                      <span className="rideDirectionLabel">Retour</span>
                      <StartRounded sx={{ color: 'rgba(123, 5, 250)', transform: "rotate(180deg)" }} />
                    </Stack>
                  </div>
                  <Stack direction="column">
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <AccessTimeOutlinedIcon />
                      <div>
                        {getTime(ride.returnMeetingTime)}
                      </div>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <div>
                        <LocationOnIcon />
                      </div>
                      <div>
                        {ride.returnMeetingPoint?.address}
                      </div>
                    </Stack>
                  </Stack>
                </Stack>
              </div>
            </div>
          </Stack>
        </div>
      </Stack>
    </div>
  </div>;
}

export const EventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { eventsAndRides, teamId } = useTeamContext();
  const data = useMemo(() => eventsAndRides.find(ev => ev.event.id === eventId), [eventsAndRides, eventId]);

  if (!data)
    return null;

  return (
    <Stack direction='column'  >

      <div className="CardsAvailableCars" id={`event_${data?.event.id}`}>
        <Card sx={{ backgroundColor: "rgba(123, 5, 250, 0.1)", color: 'black', maxWidth: '100%', paddingY: '0rem', marginX: '0.5rem', borderRadius: '20px', marginTop: '2.5rem' }}>
          <CardActionArea>
            <CardContent>
              <div className='EventDescription'>
                <Stack direction='row' gap={2} justifyContent={'space-between'}>

                  <div className='EventLocation'>
                    <div className='IconLocation'>
                      <LocationOnIcon sx={{ color: 'rgba(123, 5, 250)' }} />
                      <div className='Location'>
                        <Typography variant="h6" sx={{ paddingX: '0rem', margin: '0' }}>
                          {data?.event.location.name}
                        </Typography>
                      </div>
                    </div>
                    <div className='AddressLocation'>
                      <Typography variant="body2" sx={{ paddingX: '0.5rem', margin: '0' }}>{data?.event.location.address}
                      </Typography>
                    </div>
                  </div>


                  <div className='EventTime'>
                    <div className='IconLocation'>
                      <AccessTimeOutlinedIcon sx={{ color: 'rgba(123, 5, 250)' }} />
                      <div className='Location'>
                        <Stack direction='column' gap={1} justifyContent={'center'} alignItems={'center'}>
                          <Typography variant="h6" sx={{ paddingX: '0.2rem', margin: '0' }}>
                            {getTime(data.event.startTime)}
                          </Typography>

                        </Stack>
                      </div>

                    </div>
                    <Stack direction='column' justifyContent={'center'} marginY={'0.5rem'} >
                      <Button onClick={() => navigate(`/team/${teamId}/event/${eventId}/applyAsDriver`)} variant="contained" color='secondary' sx={{ position: 'sticky', width: '100%' }} >Je me propose</Button>
                    </Stack>
                  </div>
                </Stack>
              </div>

            </CardContent>
          </CardActionArea>
        </Card>
        <div className=''>
          {/* <Stack direction='column' justifyContent='flex-start' alignContent='center' alignItems='center' margin={'0.5rem'} >
            <Button onClick={() => navigate(`/team/${teamId}/event/${eventId}/applyAsDriver`)} variant="contained" color='secondary' sx={{ position: 'sticky', width: '90%' }} >Je me propose</Button>
          </Stack> */}
        </div>
        <div>
          <Stack direction='column' spacing={1} justifyContent='center' alignContent='center' alignItems='center' lineHeight='1rem' marginY={'1rem'} marginX={'1rem'} >
            {data?.rides?.map(ride => <Card sx={{ backgroundColor: 'white', color: 'black', width: '98%', borderRadius: '30px ' }}>
              <Ride ride={ride} />
              <Stack direction='row' justifyContent='center' alignContent='center' alignItems='center' spacing={1} marginTop='1rem'>
                <Button variant="contained" color='primary' sx={{ width: '90%' }} >Réserver</Button>
              </Stack>
            </Card>)}
          </Stack>
        </div>

        {/* <div className=''>
          <Stack direction='column' justifyContent='flex-end' alignContent='center' alignItems='center' spacing={1} marginTop='2rem'>
            <Button onClick={() => navigate(`/team/${teamId}/event/${eventId}/applyAsDriver`)} variant="contained" color='secondary' sx={{ position: 'sticky' }} >Je me propose</Button>
          </Stack>
        </div> */}
      </div>
    </Stack>
  );
}

