import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IUser } from "../models/IUser";
import { UserService } from "../services/UserService";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ITeamMembership } from "../models/ITeamMembership";
import { ITeam } from "../models/ITeam";
import { TeamsService } from "../services/TeamsService";
import { IPassenger } from "../models/IPassenger";
import { NotificationsService } from "../services/NotificationsService";
import { INotification } from "../models/INotification";
import { IRide } from "../models/IRide";

export interface IUserContext {
    isLoading: boolean;
    loaded: boolean;
    user: IUser;
    myTeams: ITeamMembership[];
    myRides: IRide[];
    myPassengers: IPassenger[];
    notifications: INotification[];
    selectedTeamId: string;
    selectTeam: (teamId: string) => Promise<void>;
    requestJoinTeam: (joinCode: string) => Promise<void>;
    onboard: () => Promise<IUser>;
    ongoingOnBoarding: boolean;
    addToReadNotification: (notification: INotification) => Promise<void>;
    updateUserInfo: (userInfo: IUser) => Promise<void>;
    addPassengersToCurrentUser: (passenger: IPassenger[]) => Promise<IPassenger[]>;
    addTeam: (team: ITeam) => Promise<ITeam>;
}

const DEFAULT_CONTEXT: IUserContext = {
    isLoading: false,
    loaded: false,
    user: {} as IUser,
    selectedTeamId: null,
    myTeams: [],
    myRides: [],
    myPassengers: [],
    notifications: [],
    selectTeam: async () => { },
    requestJoinTeam: async () => { },
    onboard: async () => ({} as IUser),
    ongoingOnBoarding: false,
    addTeam: async () => ({} as ITeam),
    updateUserInfo: async () => { },
    addPassengersToCurrentUser: async () => [],
    addToReadNotification: async () => { }
};

const UserContext = createContext<IUserContext>(DEFAULT_CONTEXT);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    // const navigate = useNavigate();
    const userService = useMemo(() => new UserService(instance), [instance]);
    const teamsService = useMemo(() => new TeamsService(instance), [instance]);
    const notificationsService = useMemo(() => new NotificationsService(instance), [instance]);
    const [context, setContext] = useState<IUserContext>(DEFAULT_CONTEXT);
    const readNotificationQueueRef = useRef<string[]>([]);
    const notificationTimeoutRef = useRef<NodeJS.Timeout>();
    const addToReadNotification = useCallback(async (notification: INotification) => {
        try {
            if (readNotificationQueueRef.current.indexOf(notification.id) < 0) {
                readNotificationQueueRef.current.push(notification.id);
            }
            if (notificationTimeoutRef.current) {
                clearTimeout(notificationTimeoutRef.current);
            }
            const toRead = [...readNotificationQueueRef.current];
            notificationTimeoutRef.current = setTimeout(() => {
                notificationsService.readNotifications(toRead).then(() => {
                    const now = new Date().toISOString();
                    setContext(prev => ({
                        ...prev,
                        notifications: prev.notifications.map(n => toRead.indexOf(n.id) < 0
                            ? { ...n, readOn: now }
                            : n)
                    }));
                    readNotificationQueueRef.current = [];
                });
            }, 5000);
        } catch (error) {
            console.error(error);
        }
    }, [notificationsService, readNotificationQueueRef, notificationTimeoutRef, setContext]);

    const updateUserInfo = useCallback(async (userInfo: IUser) => {
        try {
            userService.updateCurrentUserInfo(userInfo);
            setContext(prev => ({ ...prev, loaded: false }));
        } catch (error) {
            console.error(error);
        }
    }, [userService, setContext]);

    const addPassengersToCurrentUser = useCallback(async (passengers: IPassenger[]) => {
        try {
            passengers = await userService.addPassengersToCurrentUser(passengers);
            setContext(prev => ({ ...prev, loaded: false }));
            return passengers;
        } catch (error) {
            console.error(error);
        }
    }, [userService, setContext]);

    const requestJoinTeam = useCallback(async (code: string, comments?: string) => {
        try {

            userService.requestJoinTeam(code, comments);
            setContext(prev => ({ ...prev, loaded: false }));
        } catch (error) {
            console.error(error);
        }
    }, [userService, setContext]);

    const selectTeam = useCallback(async (teamId: string) => {
        if (!teamId)
            localStorage.removeItem('currentUser_selectedTeamId');
        else
            localStorage.setItem('currentUser_selectedTeamId', teamId);
        setContext(prev => ({ ...prev, selectedTeamId: teamId }));
    }, []);

    const addTeam = useCallback(async (team: ITeam) => {
        try {

            const created = await teamsService.addTeam(team);
            setContext(prev => ({ ...prev, loaded: false }));
            return created;
        } catch (error) {
            console.error(error);
        }
    }, [teamsService, setContext]);

    const onboard = useCallback(async () => {
        try {

            setContext(prev => ({ ...prev, ongoingOnBoarding: true }));
            const user = await userService.onboard();
            setContext(prev => ({ ...prev, user, ongoingOnBoarding: false }));
            return user;
        } catch (error) {
            console.error(error);
        }
    }, [userService, setContext]);

    const loadUserData = useCallback(async () => {
        try {
            setContext(prev => ({ ...prev, isLoading: true }));

            const myTeamsPromise = userService.getMyTeams();
            const myRidesPromise = userService.getMyRides();
            const myPassengersPromise = userService.getMyPassengers();
            const notificationsPromise = notificationsService.getMyNotifications();
            const user = (await userService.getCurrentUserData()) || { isNewcomer: true } as IUser;
            const [myTeams, myRides, myPassengers, notifications] = await Promise.all([myTeamsPromise, myRidesPromise, myPassengersPromise, notificationsPromise]);
            const previousSelectedTeamId = localStorage.getItem('currentUser_selectedTeamId');
            setContext(prev => ({
                ...prev,
                user,
                myTeams,
                myRides,
                myPassengers,
                notifications,
                selectedTeamId: previousSelectedTeamId,
                selectTeam,
                requestJoinTeam,
                addTeam,
                onboard,
                updateUserInfo,
                addPassengersToCurrentUser,
                addToReadNotification
            }));
        } catch (error) {
            console.error("Could not load user data");
        }
        finally {
            setContext(prev => ({ ...prev, isLoading: false, loaded: true }));
        }
    }, [setContext, userService, notificationsService, selectTeam,
        requestJoinTeam, addTeam, onboard, updateUserInfo, addPassengersToCurrentUser, addToReadNotification]);

    useEffect(() => {
        if (!context.loaded && isAuthenticated && !context.isLoading)
            loadUserData();
    }, [context.loaded, context.isLoading, loadUserData, isAuthenticated]);

    return <UserContext.Provider value={context}>
        {children}
    </UserContext.Provider>
}