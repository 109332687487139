import { IPublicClientApplication } from "@azure/msal-browser";
import { AccessTokenBasedService } from "./AccessTokenBasedService";
import { ITeam } from "../models/ITeam";
import { ITeamMembership } from "../models/ITeamMembership";
import { IEventAndRides } from "../models/IEventAndRides";
import { IEvent } from "../models/IEvent";

export class TeamsService extends AccessTokenBasedService {

    constructor(public msalInstance: IPublicClientApplication) {
        super(msalInstance);
    }

    async addEvent(teamId: string, event: IEvent): Promise<IEvent> {
        try {
            const client = await this.getClient();
            event = { ...event, teamId };
            const result = await client.post<IEvent, IEvent>(`/Events`, event);
            this.handleError(result);
            return result as IEvent;
        } catch (error) {
            console.error('Error while trying to add event');
            throw error;
        }
    }

    async getUpcomingEvents(teamId: string): Promise<IEventAndRides[]> {
        try {
            const client = await this.getClient();
            const result = await client.get(`/teams/${teamId}/UpcomingEvents`);
            this.handleError(result);
            return result as IEventAndRides[];
        } catch (error) {
            console.error('Error while trying to get events');
            return [];
        }
    }

    async getMembers(teamId: string): Promise<ITeamMembership[]> {
        try {
            const client = await this.getClient();
            const result = await client.get<ITeamMembership[]>(`/teams/${teamId}/members`);
            this.handleError(result);
            return result as ITeamMembership[];
        } catch (error) {
            console.error('Error while trying to get team members');
            throw new Error("Could not get team members");
        }
    }

    async addTeam(team: ITeam): Promise<ITeam> {
        try {
            const client = await this.getClient();
            const result = await client.post<ITeam, ITeam>(`/teams`, team);
            this.handleError(result);
            return result as ITeam;
        } catch (error) {
            console.error('Error while trying to add new team');
            throw new Error("Could not add a new team");
        }
    }

    async removeTeamMember(teamId: string, userId: string): Promise<void> {
        try {
            const client = await this.getClient();
            const result = await client.delete(`/teams/${teamId}/members/${userId}`);
            this.handleError(result);
        } catch (error) {
            console.error('Error while trying to remove member from team');
            throw new Error("Could not remove member from team");
        }
    }

    async updateTeam(team: ITeam): Promise<void> {
        try {
            const client = await this.getClient();
            if (!team.id)
                throw new Error("Team id is not specified, team cannot be updated");
            const result = await client.put<ITeam, unknown>(`/teams/${team.id}`, team);
            this.handleError(result);
        } catch (error) {
            console.error('Error while trying to update team');
            throw new Error("Could not update team");
        }
    }

    async regenerateJoinCode(teamId: string): Promise<string> {
        try {
            const client = await this.getClient();
            const result = await client.post<unknown, string>(`/teams/${teamId}/regenerateJoinCode`);
            this.handleError(result);
            return result as string;
        } catch (error) {
            console.error('Error while trying to regenerate team join code');
            throw new Error("Could not regenerate team join code");
        }
    }

}