import { Button, Stack, TextField } from "@mui/material";
import { IRide } from "../../models/IRide";
import { useCallback, useEffect, useRef, useState } from "react";
import { LocationPicker } from "../LocationPicker/LocationPicker";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { useTeamContext } from "../../contexts/TeamContext";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Wizard, useWizard } from 'react-use-wizard';
import Dayjs from 'dayjs';
import { getTime } from "../../helpers/date-helpers";
import { AnimatePresence } from "framer-motion";
import AnimatedStep from "../common/AnimatedStep";

interface IStepProps {
    ride: IRide;
    onPropertyChanged: (property: string, value: unknown) => void;
}

const MainInfoStep = ({ ride, onPropertyChanged }: IStepProps) => {
    return <Stack direction={'column'} spacing={2} paddingX={'1.5rem'} paddingTop={'2rem'}>
        <h2>Votre proposition de Ride</h2>
        <TextField label='Nom du Ride' value={ride.name} onChange={ev => onPropertyChanged('name', ev.target.value)} />
        <TextField label='Nombre de place' value={ride.availableSeats} onChange={ev => onPropertyChanged('availableSeats', ev.target.value)} />
    </Stack>;
};

const StartInfoStep = ({ ride, onPropertyChanged }: IStepProps) => {
    return <Stack direction={'column'} spacing={2} paddingX={'1.5rem'} paddingTop={'2rem'}>
        <h2>Départ</h2>
        <TimePicker label='Heure de départ' value={Dayjs(ride.startMeetingTime)} onChange={v => onPropertyChanged('startMeetingTime', v)} />
        <LocationPicker location={ride.startMeetingPoint} onLocationChanged={async l => onPropertyChanged('startMeetingPoint', l)} />
    </Stack>;
};

const ReturnInfoStep = ({ ride, onPropertyChanged }: IStepProps) => {
    return <Stack direction={'column'} spacing={2} paddingX={'1.5rem'} paddingTop={'2rem'}>
        <h2>Retour</h2>
        <TimePicker label='Heure de retour' value={Dayjs(ride.returnMeetingTime)} onChange={v => onPropertyChanged('returnMeetingTime', v)} />
        <LocationPicker location={ride.returnMeetingPoint} onLocationChanged={async l => onPropertyChanged('returnMeetingPoint', l)} />
    </Stack>;
};

const SummaryStep = ({ ride, onPropertyChanged }: IStepProps) => {
    return <Stack direction={'column'} spacing={2} paddingX={'1.5rem'} paddingTop={'2rem'}>
        <h2>{ride.name}</h2>
        <label>Nombre de places:</label><strong>{ride.availableSeats}</strong>
        <label>Départ</label>
        {ride.startMeetingPoint?.name} à {getTime(ride.startMeetingTime)}
        <address>
            {ride.startMeetingPoint?.address}
        </address>
        <label>Retour</label>
        {ride.returnMeetingPoint?.name} à {getTime(ride.returnMeetingTime)}
        <address>
            {ride.returnMeetingPoint?.address}
        </address>
        <TextField label='Commentaire' multiline rows={4} value={ride.comments} onChange={ev => onPropertyChanged('comments', ev.target.value)} />
    </Stack>;
};

interface IWizardFooterProps {
    ride: IRide;
    saveRide: () => void;
    cancel: () => void;
}

const WizardFooter = ({ saveRide }: IWizardFooterProps) => {
    const { isLastStep, isFirstStep, nextStep, previousStep } = useWizard();
    return <>
        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} paddingX={'1.5rem'} sx={{paddingTop:'1.5rem', backgroundColor:'white', zIndex:5 }} >
            {!isFirstStep && <Button variant="contained" color="secondary" onClick={() => previousStep()} sx={{width:'40%'}}>Précédent</Button>}
            <Button variant="contained" color='primary' onClick={() => isLastStep ? saveRide() : nextStep()} sx={{width:'40%'}}>{isLastStep ? 'Valider' : 'Suivant'}</Button>
        </Stack>
    </>;
};

export const RideForm = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const { user } = useUserContext();
    const { applyAsDriver } = useTeamContext();
    // const event = useMemo(() => eventsAndRides.find(e => e.event.id === eventId), [eventId, eventsAndRides]);
    const navigate = useNavigate();
    const previousStep = useRef<number>(0);
    const [data, setData] = useState<IRide>({
        driverId: null,
        eventId,
        availableSeats: 1,
        name: ``,
        comments: '',
        bookings: [],
        teamId: null,
        id: null
    });

    useEffect(() => {
        if (!user)
            return;
        setData({
            driverId: user.id,
            eventId,
            availableSeats: 1,
            name: `Voiture de ${user.firstName}`,
            comments: '',
            bookings: [],
            teamId: null,
            id: null
        });
    }, [user, setData, eventId]);

    const onPropertyChanged = useCallback((property: string, value: any) => {
        const n = parseInt(value as string);
        let ensuredValue = isNaN(n) ? value : n;
        if (!!value.$d) {
            ensuredValue = value.$d?.toISOString();
        }
        setData(prev => ({ ...prev, [property]: ensuredValue }));
    }, [setData]);

    const saveRide = useCallback(async () => {
        try {
            await applyAsDriver(eventId, data);
            navigate('/');
        } catch (error) {
            console.error('The ride could not be saved');
        }
    }, [applyAsDriver, data, eventId, navigate]);

    const cancel = useCallback(() => {
        setData({} as IRide);
        navigate(-1);
    }, [navigate]);

    return <div className="RideForm">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction='column' gap={2}>
                <Wizard footer={<WizardFooter ride={data} saveRide={saveRide} cancel={cancel} />}
                    wrapper={<AnimatePresence initial={false} mode="wait" />}  >
                    <AnimatedStep key={'MainInfoStep'} previousStep={previousStep}>
                        <MainInfoStep ride={data} onPropertyChanged={onPropertyChanged} />
                    </AnimatedStep>
                    <AnimatedStep key={'StartInfoStep'} previousStep={previousStep}>
                        <StartInfoStep ride={data} onPropertyChanged={onPropertyChanged} />
                    </AnimatedStep>
                    <AnimatedStep key={'MReturnInfoStepainInfoStep'} previousStep={previousStep}>
                        <ReturnInfoStep ride={data} onPropertyChanged={onPropertyChanged} />
                    </AnimatedStep>
                    <AnimatedStep key={'SummaryStep'} previousStep={previousStep}>
                        <SummaryStep ride={data} onPropertyChanged={onPropertyChanged} />
                    </AnimatedStep>
                </Wizard>
                {/* <Stack direction='row' spacing={2} justifyContent='center' paddingBottom={'2.5rem'}>
                <Button variant="contained" color="warning" sx={{backgroundColor:'white', color:'red'}} onClick={() => cancel()}>Annuler</Button>
                </Stack> */}
            </Stack>
        </LocalizationProvider>
    </div >;
};