import { Stack, Avatar, Grid2, IconButton } from '@mui/material';
import { ITeam } from "../../models/ITeam";
import { ITeamMembership } from '../../models/ITeamMembership';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import profileIcon from '../../assets/icons/ProfileIcon.svg';
import { useCallback, useMemo } from 'react';
import { useDialogs } from '../common/Dialog/DialogProvider';
import { useUserContext } from '../../contexts/UserContext';
import { IUser } from '../../models/IUser';

export const AdminToast = () => {
    return <span style={{
        // display: 'inline-block',
        minWidth: '60px',
        fontSize: '12px',
        textAlign: 'center',
        alignContent: 'center',
        // paddingLeft: '3px',
        // paddingRight: '3px',
        padding: '0.2rem',
        borderRadius: '6px',
        backgroundColor: 'red',
        color: 'white',
    }}>ADMIN</span>;
};

export interface ITeamMemberProps {
    currentUser: IUser;
    isCurrentUserAdmin: boolean;
    membership: ITeamMembership;
    removeMember?: (membership: ITeamMembership) => void;
}

export const TeamMember = ({ membership, removeMember, isCurrentUserAdmin, currentUser }: ITeamMemberProps) => {


    if (!membership?.user)
        return null;
    const { user: member, isAdmin } = membership;


    return <div key={membership.userId} className="TeamMember" style={{
        borderRadius: '10px',
        marginBottom: '0.3rem',
        padding: '0.5rem',
        backgroundColor: 'rgba(123, 5, 250, 0.1)'
    }}>
        <Grid2 container spacing={2}>
            <Grid2 size={10}>
                <Stack direction='row' alignContent='center' alignItems='center' spacing={3}>
                    <Avatar src={member.avatar || profileIcon} />
                    <span>{member.displayName}</span>
                    {isAdmin && <AdminToast />}
                </Stack>
            </Grid2>
            <Grid2 size={2}>
                {!!removeMember && isCurrentUserAdmin && currentUser.id !== member.id && <IconButton onClick={() => removeMember(membership)}>
                    <PersonRemoveIcon />
                </IconButton>}
            </Grid2>
        </Grid2>
    </div>;
};

export interface ITeamMembersProps {
    team: ITeam;
    members: ITeamMembership[];
    removeMember?: (member: ITeamMembership) => void;
}

export const TeamMembers = ({ members, removeMember }: ITeamMembersProps) => {

    const { user: currentUser } = useUserContext();
    const isCurrentUserAdmin = useMemo(() => currentUser.isSuperAdmin || !!members.find(m => m.userId === currentUser.id && m.isAdmin), [members, currentUser]);
    const { confirm } = useDialogs();

    const getMemberDisplayName = useCallback((userId: string) => {
        return members.find(m => m.userId === userId)?.user?.displayName;
    }, [members]);

    const confirmRemoveMember = useCallback(async (membership: ITeamMembership) => {
        const memberDisplayName = getMemberDisplayName(membership.userId);
        if (await confirm(<>
            <p>{`Souhaitez-vous vraimer retirer ${memberDisplayName} de l'équipe? `}</p></>,
            'Oui', 'Non')) {
            removeMember(membership);
        }
    }, [getMemberDisplayName, confirm, removeMember]);

    const sortedMembers = useMemo<ITeamMembership[]>(() => members.sort((a) => a.isAdmin ? -1 : 1), [members]);
    return <div className="TeamMembers" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        {sortedMembers.map(m => <TeamMember key={m.userId}
            currentUser={currentUser}
            isCurrentUserAdmin={isCurrentUserAdmin}
            membership={m}
            removeMember={confirmRemoveMember} />)}
    </div>;
}