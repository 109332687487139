import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RidesService } from "../services/RidesService";
import { IEventAndRides } from "../models/IEventAndRides";
import { IRide } from "../models/IRide";
import { UserService } from "../services/UserService";
import { useMsal } from "@azure/msal-react";
import { ITeam } from "../models/ITeam";
import { useUserContext } from "./UserContext";
import { TeamsService } from "../services/TeamsService";
import { ITeamMembership } from "../models/ITeamMembership";
import { useAppContext } from "./AppContext";
import { IEvent } from "../models/IEvent";

export interface ITeamContext {
    teamId: string;
    teamName: string;
    team?: ITeam;
    isLoading: boolean;
    eventsAndRides: IEventAndRides[];
    currentUserIsAdmin: boolean;
    getMembers: () => Promise<ITeamMembership[]>;
    removeMember: (membership: ITeamMembership) => Promise<void>;
    updateTeam: (team: ITeam, refresh: boolean) => void;
    regenerateJoinCode: () => Promise<string>;
    addEvent: (event: IEvent) => Promise<IEvent>;
    applyAsDriver: (eventId: string, ride: IRide) => Promise<void>;
    bookRide: (eventId: string, rideId: string, passengerIds: string[], comments?: string) => Promise<void>;
}

const defaultContext: ITeamContext = {
    teamId: '',
    teamName: '',
    team: null,
    isLoading: true,
    eventsAndRides: [],
    currentUserIsAdmin: false,
    updateTeam: () => { },
    removeMember: async () => { },
    regenerateJoinCode: async () => '',
    applyAsDriver: async () => { },
    bookRide: async () => { },
    getMembers: async () => [],
    addEvent: async () => null
};

const TeamContext = createContext<ITeamContext>(defaultContext);


export interface ITeamContextProviderProps {
    children: any;
    teamId: string;
}

export const useTeamContext = () => useContext(TeamContext);



export const TeamContextProvider = (props: ITeamContextProviderProps) => {
    const { instance } = useMsal();
    const { displayMessage } = useAppContext();
    const { selectedTeamId } = useUserContext();
    const [refreshStamp, setRefreshStamp] = useState<number>(null);
    const teamId = props.teamId || selectedTeamId;
    const userService = useMemo(() => new UserService(instance), [instance]);
    const ridesService = useMemo(() => !!teamId && new RidesService(instance, teamId), [instance, teamId]);
    const teamsService = useMemo(() => new TeamsService(instance), [instance]);

    const addEvent = useCallback(async (event: IEvent) => {
        try {
            if (!teamId || !event)
                throw new Error('Invalid parameters. Cannot add event');
            event = { ...event, teamId };
            setRefreshStamp(+new Date());
            return await teamsService?.addEvent(teamId, event);
        } catch (e) {
            displayMessage(`Vous ne pouvez pas ajouter d'évènement dans cette équipe...`, 'error');
            throw e;
        }
    }, [teamsService, teamId, setRefreshStamp, displayMessage]);

    const applyAsDriver = useCallback(async (eventId: string, ride: IRide) => {
        try {
            if (!teamId || !eventId || !ride)
                throw new Error('Invalid parameters. Cannot apply as driver');

            await ridesService?.applyAsDriver(teamId, eventId, ride);
            setRefreshStamp(+new Date());
        } catch (e) {
            displayMessage(`Vous ne pouvez pas proposer un Ride pour cet évènement...`, 'error');
            throw e;
        }
    }, [ridesService, teamId, setRefreshStamp, displayMessage]);

    const bookRide = useCallback(async (eventId: string, rideId: string, passengerIds: string[], comments?: string) => {
        try {
            if (!teamId || !eventId || !rideId)
                throw new Error('Invalid parameters. Cannot book ride');

            await ridesService?.bookRide(teamId, eventId, rideId, passengerIds, comments);
            setRefreshStamp(+new Date());
        } catch (e) {
            displayMessage(`Le réservation sur ce Ride est impossible...`, 'error');
            throw e;
        }
    }, [ridesService, teamId, setRefreshStamp, displayMessage]);

    const updateTeam = useCallback(async (team: ITeam, refresh: boolean = true) => {
        try {
            if (!team)
                throw new Error('Invalid parameters. Cannot update team');

            await teamsService?.updateTeam(team);
            if (refresh)
                setRefreshStamp(+new Date());
        } catch (e) {
            displayMessage(`Les informations de l'équipe ne peuvent être mises à jour...`, 'error');
            throw e;
        }
    }, [teamsService, setRefreshStamp, displayMessage]);

    const getMembers = useCallback(async () => {
        try {
            if (!teamId)
                throw new Error('Invalid parameters. Cannot load team members');

            return await teamsService?.getMembers(teamId);
        } catch (e) {
            displayMessage(`Les membres de l'équipe n'ont pas pu être récupérés...`, 'error');
            throw e;
        }
    }, [teamId, teamsService, displayMessage]);

    const regenerateJoinCode = useCallback(async () => {
        try {

            // TODO JUST TO TEST ERROR MESSAGE, FAKE ERROR, MUST BE REMOVED WHEN GOOD WITH ERROR MESSAGE
            throw new Error("FAKE ERROR");
            // if (!teamId)
            //     throw new Error('Team Id is not set, cannot regenerate join code!');
            // const newCode = (await teamsService?.regenerateJoinCode(teamId)) || '';
            // return newCode;
        } catch (e) {
            displayMessage(`Le code d'accès de l'équipe ne peut pas être regénéré`, 'error', 50000);
            throw e;
        }
    }, [displayMessage]);
    // }, [teamsService, teamId, displayMessage]);

    const removeMember = useCallback(async (membership: ITeamMembership) => {
        try {
            if (!teamId)
                throw new Error('Team Id is not set, cannot remove member');

            await teamsService?.removeTeamMember(teamId, membership.userId);
        } catch (e) {
            displayMessage(`Le membre ${membership.user.displayName} ne peut pas être retiré de l'équipe`, 'error');
            throw e;
        }
    }, [teamsService, teamId, displayMessage]);

    const [context, setContext] = useState<ITeamContext>({
        ...defaultContext,
        applyAsDriver,
        bookRide,
        teamId,
        getMembers,
        updateTeam,
        regenerateJoinCode,
        removeMember,
        addEvent
    });


    const loadTeam = useCallback(async () => {
        try {
            setContext(prev => ({ ...prev, isLoading: true }));
            const eventsAndCarRides = ridesService ? await teamsService.getUpcomingEvents(teamId) : [];
            const myTeams = await await userService.getMyTeams();
            const currentMembership = myTeams.find(tm => tm.teamId === teamId);
            const team = currentMembership?.team;
            const currentUserIsAdmin = currentMembership?.isAdmin || false;
            const teamName = team?.name;
            setContext(prev => ({
                ...prev,
                eventsAndRides: eventsAndCarRides,
                team,
                teamName,
                currentUserIsAdmin
            }));
        } catch (error) {
            console.error("Could not load team");
        }
        finally {
            setContext(prev => ({ ...prev, isLoading: false }));
        }
    }, [ridesService, teamsService, userService, teamId]);

    useEffect(() => {
        loadTeam();
    }, [loadTeam, refreshStamp]);

    return <TeamContext.Provider value={context}>
        {props.children}
    </TeamContext.Provider>
}