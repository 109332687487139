import { Button, Stack, TextField } from "@mui/material";
import { IEvent } from "../../models/IEvent";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { LocationPicker } from "../LocationPicker/LocationPicker";
import Dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useTeamContext } from "../../contexts/TeamContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";


export interface IEventFormProps {
    event: IEvent;
}

export const EventForm = ({ event }: IEventFormProps) => {

    const { addEvent, teamId } = useTeamContext();
    const navigate = useNavigate();
    const [editingEvent, setEditingEvent] = useState<IEvent>(event);
    const hasChanges = useMemo<boolean>(() => editingEvent.name !== event.name
        || editingEvent.location?.name !== event.location?.name
        || editingEvent.location?.address !== event.location?.address
        || editingEvent.startTime !== event.startTime
        || editingEvent.endTime !== event.endTime, [editingEvent, event]);

    const onPropertyChanged = useCallback((property: string, value: any) => {
        setEditingEvent((prev => ({ ...prev, [property]: value })));
    }, [setEditingEvent]);

    const onPropertiesChanged = useCallback((properties: Record<string, any>) => {
        setEditingEvent((prev => ({ ...prev, ...properties })));
    }, [setEditingEvent]);

    const saveEvent = useCallback(async () => {
        try {
            await addEvent(editingEvent);
            navigate(`/team/${teamId}/events`);
        }
        catch (e) {
            console.error(e);
        }
    }, [teamId, editingEvent, addEvent, navigate]);

    return <div className="EventForm">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction='column' gap={1} paddingX={'1.5rem'}>
                <TextField label={`Nom de l'évènement`} value={editingEvent.name} onChange={ev => onPropertyChanged('name', ev.target.value)} />
                <DatePicker label="Date" onChange={v => onPropertiesChanged({ 'startTime': v, 'endTime': v })} />
                <TimePicker label='Heure de début' value={Dayjs(editingEvent.startTime)} onChange={v => onPropertyChanged('startTime', v)} />
                <TimePicker label='Heure de fin' value={Dayjs(editingEvent.endTime)} onChange={v => onPropertyChanged('endTime', v)} />
                <LocationPicker location={editingEvent.location} onLocationChanged={async l => onPropertyChanged('location', l)} />
                <Button variant="contained" color='primary' disabled={!hasChanges} onClick={() => saveEvent()}>{'Sauver'}</Button>
            </Stack>
        </LocalizationProvider>
    </div>
};