import { Groups2 } from "@mui/icons-material"
import { Avatar, Stack, Typography } from "@mui/material"
import { ITeamMembership } from "../../models/ITeamMembership";

export interface IMyTeamsProps {
    memberships: ITeamMembership[];
}

export const MyTeams = ({ memberships }: IMyTeamsProps) => {

    return <Typography>
        <Stack direction='column' justifyContent={'space-between'} gap={1} alignContent={'center'}>
            {memberships.map(m => <Stack direction='row' gap={2} justifyContent={'left'} alignContent={'center'} alignItems={'center'}>
                <Avatar src={m.team?.avatar} >
                    <Groups2 />
                </Avatar>
                <span>{m.team?.name}</span>
            </Stack>)}
        </Stack>
    </Typography>
}