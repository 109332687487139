import { useCallback, useEffect, useState } from "react";
import { useTeamContext } from "../../contexts/TeamContext";
import { Loading } from "../Loading/Loading";
import { ITeam } from "../../models/ITeam";
import { TeamSettings } from "../TeamSettings/TeamSettings";
import { TeamMembers } from "../TeamMembers/TeamMembers";
import { ITeamMembership } from "../../models/ITeamMembership";
import { Avatar, Badge, Stack } from "@mui/material";
import TeamIcon from '../../assets/icons/TeamIcon.svg';
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import EditIcon from '@mui/icons-material/Edit';

export interface ITeamDetailsProps {
    team: ITeam;
    getMembers: () => Promise<ITeamMembership[]>;
    updateTeam: (team: ITeam, refresh?: boolean) => void;
    removeMember: (membership: ITeamMembership) => Promise<void>;
    currentUserIsAdmin: boolean;
    regenerateJoinCode?: () => Promise<string>;
}

export const CurrentTeamDetails = () => {
    const { team, isLoading, currentUserIsAdmin, regenerateJoinCode, updateTeam, getMembers, removeMember } = useTeamContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (!team && !isLoading) {
            navigate('/select-team');
        }
    }, [team, isLoading, navigate]);

    return <TeamDetails team={team}
        getMembers={getMembers}
        removeMember={removeMember}
        updateTeam={updateTeam}
        currentUserIsAdmin={currentUserIsAdmin}
        regenerateJoinCode={regenerateJoinCode} />;
}

export const TeamDetails = ({ team, getMembers, currentUserIsAdmin,
    updateTeam, removeMember, regenerateJoinCode }: ITeamDetailsProps) => {
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [members, setMembers] = useState<ITeamMembership[]>([]);
    const { displayMessage } = useAppContext();
    const [teamAvatar, setTeamAvatar] = useState<string>(team?.avatar);

    useEffect(() => {
        if (!!team?.avatar)
            setTeamAvatar(team.avatar);
    }, [team, setTeamAvatar]);

    const updateTeamSettings = useCallback((team: ITeam, refresh: boolean = true) => {
        updateTeam(team, refresh);
    }, [updateTeam]);

    const removeTeamMember = useCallback((membership: ITeamMembership) => {
        try {
            removeMember(membership);
        } catch (e) {
            console.error(e);
        }
    }, [removeMember]);

    const [isLoadingMembers, setIsLoadingMembers] = useState<boolean>(false);
    useEffect(() => {
        if (!team || errorMessage || isLoadingMembers || members.length > 0)
            return;

        setIsLoadingMembers(true);
        getMembers()
            .then(fetchedMembers => setMembers(fetchedMembers))
            .catch(() => setErrorMessage("Une erreur s'est produite"))
            .finally(() => setIsLoadingMembers(false));
    }, [team, getMembers, errorMessage, setErrorMessage, members, isLoadingMembers, setIsLoadingMembers]);

    const onAvatarChange = useCallback((event) => {
        if (event.target.files && event.target.files[0]) {
            // const avatar = URL.createObjectURL(event.target.files[0]);
            const [file] = event.target.files;
            if (file.size > 1500000) {
                displayMessage("La taille du fichier d'avatar de l'équipe doit être inférieure à 1.5MB", "error");
                return;
            }
            const reader = new FileReader();
            reader.onload = () => {
                const avatar = reader.result as string;
                updateTeam({ ...team, avatar });
                setTeamAvatar(avatar);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
            reader.readAsDataURL(file);
        }
    }, [setTeamAvatar, team, updateTeam, displayMessage]);

    if (errorMessage)
        return <div>{errorMessage}</div>

    if (!team)
        return <Loading />;


    return <div className="TeamDetails" style={{ padding: '2rem' }}>
        <Stack direction='column' alignContent='center' alignItems='center'>
            <label htmlFor="upload">
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                
                badgeContent={
                  <EditIcon sx={{ width: 18, height: 18, color: 'white', backgroundColor: 'rgba(123, 5, 250)', borderRadius: '50%', padding:'0.5rem' }} />
                }
              
             >
                <Avatar sx={{ width: '8rem', height: '8rem', marginTop: '1rem' }} src={teamAvatar || TeamIcon} />
                </Badge>
                <input type="file" id="upload" onChange={onAvatarChange} style={{ display: 'none' }} />
            </label>
        
        <h1>{team.name}</h1>
        </Stack>

        <h3>Membres</h3>
        {isLoadingMembers
            ? <Loading />
            : <TeamMembers members={members} team={team} removeMember={currentUserIsAdmin ? removeTeamMember : null} />
        }

        {currentUserIsAdmin && <>
            {/* <h3>Administration</h3> */}
            <TeamSettings team={team} updateTeam={updateTeamSettings} regenerateJoinCode={regenerateJoinCode} />
        </>}
    </div>
};