
import './Notifications.css';
import { useUserContext } from '../../contexts/UserContext';
import { useEffect, useMemo, useRef, useState } from 'react';
import { INotification } from '../../models/INotification';
import { IconButton, Slide, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Notification = ({ notification }: { notification: INotification }) => {
  const { addToReadNotification } = useUserContext();
  const ref = useRef();
  const observer = useMemo(() => new IntersectionObserver((entries) => {
    if (!notification.readOn) {
      for (let entry of entries) {
        if (entry.isIntersecting) {
          addToReadNotification(notification);
        }
      }
    }
  }), [addToReadNotification, notification]);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      const elementRef = ref.current;
      return () => observer.unobserve(elementRef);
    }
  }, [observer, ref]);

  return <div ref={ref} className={`NotificationItem ${!notification.readOn ? 'unread' : ''}`}>
    <Typography variant="h5" fontWeight={!notification.readOn ? 500 : 400}>
      {notification.title}
    </Typography>
    <div dangerouslySetInnerHTML={{ __html: notification.content }}></div>
  </div>
};

export const Notifications = () => {

  const { notifications } = useUserContext();
  const hasNotifications = useMemo(() => !!notifications.find(n => !n.readOn), [notifications]);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  return (
    <div className="Notification">
      <Slide direction='down' in={isPanelOpen}>
        <div className='NotificationPanel'>
          <Stack direction='column'>
            <Stack direction='row' justifyContent='space-between' sx={{ backgroundColor:'white', zIndex:50000, position:'fixed', width:'100%', padding:'0.5rem' }}>
              <Typography variant="h4" >Notifications</Typography>
              <IconButton onClick={() => setIsPanelOpen(false)} >
                <CloseIcon />
              </IconButton>
            </Stack>
            <ul style={{paddingTop:'1.5rem', zIndex:100000}}>
              {notifications.map(n => <li key={`notification_${n.id}`}>
                <Notification notification={n} />
              </li>)}
            </ul>
          </Stack>
        </div>
      </Slide>

      <svg onClick={() => setIsPanelOpen(!isPanelOpen)} className="Notif" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 253.76 305.37">
        <g id="Notification_bell_icon_set">
          <g id="Notification_bell_icon">
            <g id="bell_icon">
              <path d="M166.14,s265.59c0,21.97-17.81,39.78-39.78,39.78s-39.78-17.81-39.78-39.78h79.56Z" />
              <g id="bell">
                {/* <path stroke="white" d="M205.36,127.53c1.98,0,3.94-.12,5.86-.36-.4-6.6-.62-13.85-.62-21.84,0-.15-.02-.28-.02-.43-.49-26.64-13.35-50.25-33.07-65.34-12.43,8.76-20.55,23.21-20.55,39.57,0,26.73,21.67,48.4,48.4,48.4Z" />
                <path d="M205.36,138.7c-32.84,0-59.56-26.72-59.56-59.56,0-18.55,8.53-35.15,21.87-46.08-6.01-3.39-12.46-6.06-19.27-7.9.13-.96.2-1.93.2-2.92,0-12.28-9.95-22.23-22.23-22.23s-22.23,9.95-22.23,22.23c0,.99.07,1.96.2,2.92-35.35,9.56-61.49,41.55-62.19,79.76,0,.14-.02.28-.02.43C42.12,217.04,0,185.45,0,238.1h0c0,15.8,9.43,15.8,21.06,15.8h210.6c11.63,0,21.06,0,21.06-15.8h0c0-46.64-33.06-27.18-40.6-99.79-2.22.25-4.47.39-6.76.39Z" /> */}
                <path className="notif-1" d="m86.58,265.59c0,21.97,17.81,39.78,39.78,39.78,21.97,0,39.78-17.81,39.78-39.78h-79.56Z" />
                <path id="bell" className="notif-1" d="m42.12,105.33c0-.15.02-.28.02-.43.7-38.21,26.83-70.2,62.19-79.76-.13-.96-.2-1.93-.2-2.92,0-12.28,9.95-22.23,22.23-22.23s22.23,9.95,22.23,22.23c0,.99-.07,1.96-.2,2.92,35.35,9.56,61.49,41.55,62.19,79.76,0,.14.02.28.02.43,0,111.7,42.12,80.11,42.12,132.76h0c0,15.8-9.43,15.8-21.06,15.8h-105.3s-105.3,0-105.3,0c-11.63,0-21.06,0-21.06-15.8h0c0-52.65,42.12-21.06,42.12-132.76Z" />
              </g>
            </g>
            {/* A afficher seulement s'il y a des notifications avec le nombre de notifications */}
            {hasNotifications && <g id="notification">
              <circle id="red_circle" className="notif-2" cx="205.36" cy="79.13" r="48.4" />
            </g>}
          </g>
        </g>
      </svg>
    </div>
  );
}
