import { createContext, useContext, useMemo, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import { Alert } from "@mui/material";

export type MessageType = 'info' | 'error' | 'success' | 'warning';

export interface IAppContext {
    displayMessage: (message: string | JSX.Element, type?: MessageType, hideAfter?: number) => void;
}

const AppContext = createContext<IAppContext>({} as IAppContext);

export const useAppContext = () => useContext(AppContext);

const SlideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="up" />;
}

interface IUserMessage {
    message: string;
    hideDuration?: number;
    open?: boolean;
    type?: MessageType

}

const DEFAULT_HIDE_DURATION = 3000;

export const AppContextProvider = ({ children }) => {

    const [userMessage, setUserMessage] = useState<IUserMessage>({
        message: '',
        open: false,
        type: 'info',
        hideDuration: DEFAULT_HIDE_DURATION
    });

    const handleClose = () => {
        setUserMessage(prev => ({
            ...prev,
            open: false,
        }));
    };

    const appContext = useMemo<IAppContext>(() => ({
        displayMessage: (m, t, h) => setUserMessage(prev => ({
            ...prev,
            open: true,
            message: m,
            hideDuration: h <= 0 ? null : (h || DEFAULT_HIDE_DURATION),
            type: t || 'info'
        } as IUserMessage))
    }), [setUserMessage]);

    return <AppContext.Provider value={appContext}>
        {children}
        <Snackbar
            open={userMessage.open}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            key={'userMessage'}
            sx={{ bottom: { xs: 120, sm: 0 } }}
            autoHideDuration={userMessage.hideDuration || DEFAULT_HIDE_DURATION}
        >
            <Alert
                onClose={handleClose}
                severity={userMessage.type}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {userMessage.message}
            </Alert>
        </Snackbar>
    </AppContext.Provider>
}