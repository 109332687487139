import { IPublicClientApplication } from "@azure/msal-browser";
import { AccessTokenBasedService } from "./AccessTokenBasedService";
import { INotification } from "../models/INotification";

export class NotificationsService extends AccessTokenBasedService {

    constructor(public msalInstance: IPublicClientApplication) {
        super(msalInstance)
    }

    async getMyNotifications(): Promise<INotification[]> {
        try {
            const client = await this.getClient();
            const result = await client.get(`/api/notifications`);
            this.handleError(result);
            return result as INotification[];
        } catch (error) {
            console.error('Error while trying to get notifications');
            return [];
        }
    }

    async readNotifications(notificationIds: string[]): Promise<void> {
        try {
            const client = await this.getClient();
            const result = await client.post(`/api/notifications/read`, { notificationIds });
            this.handleError(result);
        } catch (error) {
            console.error('Error while trying to read notifications');
        }
    }
}