import { IPublicClientApplication } from "@azure/msal-browser";
import { api_scope } from "../auth/authConfig";
import { ApiClient, HttpError } from "./ApiClient";
import getConfig from '../config/config';

export abstract class AccessTokenBasedService {


    constructor(protected msalInstance: IPublicClientApplication) { }

    protected async getAccessToken(request?: { scopes: string[] }): Promise<string> {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await this.msalInstance.acquireTokenSilent({
            ...(request || { scopes: [api_scope] }),
            account: account
        });

        return response.accessToken;
    }

    protected handleError(result: unknown) {
        const error = !!(result as HttpError)?.statusCode ? result as HttpError : null;
        if (error)
            throw new Error(`[${error.statusCode} - ${error.status || 'unknown status'}] ${error.content?.error}`);
    }

    protected async getClient(): Promise<ApiClient> {
        const config = getConfig('PROD');
        const client = new ApiClient(config.api.baseUrl);
        client.accessToken = await this.getAccessToken();
        return client;
    }

}