import { Avatar, Stack, Typography } from "@mui/material";
import { IPassenger } from "../../models/IPassenger";

export interface IMyPassengersProps {
    passengers: IPassenger[];
}

export const MyPassengers = ({ passengers }: IMyPassengersProps) => {

    return <Typography>
        <Stack direction='column' justifyContent={'space-between'} gap={1} alignContent={'center'}>
            {passengers.map(psg => <Stack direction='row' gap={2} justifyContent={'left'} alignContent={'center'} alignItems={'center'}>
                <Avatar src={psg.avatar} />
                <span>{psg.name}</span>
            </Stack>)}
        </Stack>
    </Typography>;
}