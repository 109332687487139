import { useCallback, useEffect, useMemo, useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { useMsal } from "@azure/msal-react";
import { Button, Avatar, Stack, Badge, Typography } from "@mui/material";
import profileIcon from '../../assets/icons/ProfileIcon.svg';
import { IUser } from "../../models/IUser";
import { useAppContext } from "../../contexts/AppContext";
import EditIcon from "@mui/icons-material/Edit";
import { Accordion } from "../common/Accordion/Accordion";
import { AccordionSummary } from "../common/Accordion/AccordionSummary";
import { AccordionDetails } from "../common/Accordion/AccordionDetails";
import { MyInfo } from "./MyInfo";
import { MyTeams } from "./MyTeams";
import { MyPassengers } from "./MyPassengers";

type ProfileSection = 'myInfo' | 'myTeams' | 'myPassengers' | false;

export const Profile = () => {
  const { user, myTeams, myPassengers, updateUserInfo } = useUserContext();
  const { instance } = useMsal();
  const { displayMessage } = useAppContext();

  const [userInfo, setUserInfo] = useState<IUser>({ ...user });
  const [expandedSection, setExpandedSection] = useState<ProfileSection>('myInfo');
  useEffect(() => {
    if (!!user)
      setUserInfo({ ...user });
  }, [user]);

  const signOut = useCallback(async () => {
    await instance.logoutRedirect();
  }, [instance]);

  const hasChanges = useMemo(() => userInfo.lastName?.toLowerCase() !== user.lastName?.toLowerCase()
    || userInfo.firstName?.toLowerCase() !== user.firstName?.toLowerCase()
    || userInfo.avatar !== user.avatar, [user, userInfo]);

  const discardChanges = useCallback(() => {
    setUserInfo({ ...user });
  }, [setUserInfo, user]);

  const saveChanges = useCallback(async () => {
    await updateUserInfo(userInfo);
    // TODO : Notify success
  }, [userInfo, updateUserInfo]);

  const handleExpandSection = (section: ProfileSection) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpandedSection(newExpanded ? section : false);
  };



  const onAvatarChange = useCallback((event) => {
    if (event.target.files && event.target.files[0]) {
      // const avatar = URL.createObjectURL(event.target.files[0]);
      const [file] = event.target.files;
      if (file.size > 10000000) {
        displayMessage("La photo de profil doit être inférieure à 10MB", "error");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const avatar = reader.result as string;
        setUserInfo(prev => ({ ...prev, avatar }));
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
      reader.readAsDataURL(file);
    }
  }, [setUserInfo, displayMessage]);

  if (!user?.id)
    return null;


  return <div className="UserProfile">
    <Stack direction='column' paddingX={'1.5rem'}>
      <div className="UserInfo">
        <div className="headerProfile">

          <Stack direction='column' alignContent='center' alignItems='center' marginY={'1rem'}>
            <label htmlFor="upload">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <EditIcon sx={{ width: 18, height: 18, color: 'white', backgroundColor: 'rgba(123, 5, 250)', borderRadius: '50%', padding: '0.5rem' }} />
                }>
                <Avatar sx={{ width: '8rem', height: '8rem' }} src={userInfo.avatar || profileIcon} />
              </Badge>
              <input type="file" id="upload" onChange={onAvatarChange} style={{ display: 'none' }} />
            </label>
          </Stack>
          <Stack direction='column' alignContent='center' alignItems='center' paddingBottom={'1.5rem'}>
            <h1 style={{ margin: 0 }}>{user.displayName || `${user.firstName} ${user.lastName}`}</h1>
            <h3 style={{ margin: 0 }}>Membre de {Object.keys(user.teamMemberships).length} équipes</h3>
          </Stack>
        </div>

        <Accordion expanded={expandedSection === 'myInfo'} onChange={handleExpandSection('myInfo')}>
          <AccordionSummary>
            <Typography variant='h6'>Mes informations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MyInfo user={userInfo}
              hasChanges={hasChanges}
              onChange={setUserInfo}
              onSave={saveChanges}
              onDiscard={discardChanges}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandedSection === 'myTeams'} onChange={handleExpandSection('myTeams')}>
          <AccordionSummary>
            <Typography variant='h6'>Mes équipes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MyTeams memberships={myTeams} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandedSection === 'myPassengers'} onChange={handleExpandSection('myPassengers')}>
          <AccordionSummary>
            <Typography variant='h6'>Mes passagers</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MyPassengers passengers={myPassengers} />
          </AccordionDetails>
        </Accordion>

        {/* <h2>Mes informations</h2>
               
                <Stack direction={'column'} spacing={1} >
                    <TextField label='Adresse e-mail' disabled value={userInfo.emailAddress} />
                    <TextField label='Prénom' value={userInfo.firstName} onChange={ev => setUserInfo(prev => ({ ...prev, firstName: ev.target.value }))} />
                    <TextField label='Nom' value={userInfo.lastName} onChange={ev => setUserInfo(prev => ({ ...prev, lastName: ev.target.value }))} />

                    <Stack direction='column' spacing={1.5} alignItems={'center'} width={'100%'}>
                        {hasChanges && <>
                            <Button variant="contained" color='primary' onClick={() => saveChanges()} sx={{ width: '90%' }} >Valider</Button>
                            <Button variant="contained" color='secondary' onClick={() => discardChanges()} sx={{ width: '90%' }}>Annuler</Button>
                        </>}
                    </Stack>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' alignContent='center' flex={1} sx={{backgroundColor:'rgba(123, 5, 250, 0.1)', borderRadius:'10px', marginBottom:'1rem'}}>
                <h2>Mes équipes</h2>
                <ArrowForwardIosIcon sx={{ color: 'rgba(123, 5, 250)', fontSize: '1.5rem' }} />
                </Stack>
                {/* <div className="MyTeams" style={{ padding: '1rem' }}>
                    <Stack direction='column' justifyContent={'space-between'} gap={1} alignContent={'center'}>
                        {Object.keys(user.teamMemberships).map(mk => <Stack direction='row' gap={2} justifyContent={'left'} alignContent={'center'} alignItems={'center'}>
                            <Avatar src={user.teamMemberships[mk].team?.avatar} >
                                <Groups2 />
                            </Avatar>
                            <span>{user.teamMemberships[mk].team?.name}</span>
                        </Stack>)}
                    </Stack>
                </div> */}
        {/* <Stack direction='row' justifyContent='space-between' alignItems='center' alignContent='center' sx={{backgroundColor:'rgba(123, 5, 250, 0.1)', borderRadius:'10px', marginBottom:'1rem'}}>
                <h2>Mes passagers</h2>
                
                <ArrowForwardIosIcon sx={{ color: 'rgba(123, 5, 250)', fontSize: '1.5rem' }} />
               
                </Stack> */}
        {/* <div className="MyPassengersS" style={{ padding: '1rem' }}>
                    <Stack direction='column' justifyContent={'space-between'} gap={1} alignContent={'center'}>
                        {user.responsibleOf.map(psg => <Stack direction='row' gap={2} justifyContent={'left'} alignContent={'center'} alignItems={'center'}>
                            <Avatar src={psg.avatar} />
                            <span>{psg.name}</span>
                        </Stack>)}
                    </Stack>
                </div> */}
      </div>


      {/* <h2>Session</h2> */}
      <div className="CenterButton">
        <Button onClick={() => signOut()} variant="contained" color="warning" sx={{ width: '100%' }}>Se deconnecter</Button>
      </div>
    </Stack>
    <div style={{ height: '50px' }}></div>
  </div>
};