import './EventCard.css';
import { IEventAndRides } from '../../models/IEventAndRides';
import { Button, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTeamContext } from '../../contexts/TeamContext';
import { useNavigate } from 'react-router-dom';
import { getDayNumber, getDayShortName, getMonthShortName, getTime } from '../../helpers/date-helpers';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IRide } from '../../models/IRide';
import { useUserContext } from '../../contexts/UserContext';
import { DirectionsCar } from '@mui/icons-material';
import { trimTo } from '../../helpers/strings';

export interface IEventCardProps {
  eventData: IEventAndRides;
}


export function EventCard(props: IEventCardProps) {
  const { eventData } = props;
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { teamId } = useTeamContext();

  const goToRideBooking = useCallback(async () => {
    navigate(`/team/${teamId}/event/${eventData.event.id}/book`);
  }, [navigate, eventData, teamId]);

  const applyAsDriver = useCallback(async () => {
    navigate(`/team/${teamId}/event/${eventData.event.id}/applyAsDriver`);
  }, [navigate, eventData, teamId]);


  const iAmADriver = useMemo(() => !!eventData?.rides?.find(r => r.driverId === user.id), [user, eventData]);
  const noRides = (eventData.rides?.length || 0) === 0;
  const availableSeats = useMemo(() => {
    const seats = eventData.rides?.reduce((acc, ride: IRide) => {
      const rideAvailableSeats = ride.availableSeats - (ride.bookings?.length || 0);
      return acc + rideAvailableSeats;
    }, 0);
    return seats || 0;
  }, [eventData]);

  return (
    <div className="CardsAvailableCars" id={`event_${eventData.event.id}`} >

      <div className='AvailableCarsCard'>

        <div className='EventDateCard'>
          <div className='EventDate'>
            <div className='DayDate'>{getDayShortName(eventData.event.startTime)}</div>
            <div className='NumberDayDate'>{getDayNumber(eventData.event.startTime)}</div>
            <div className='MonthDate'>{getMonthShortName(eventData.event.startTime)}</div>
            <div className='HourEvent'>{getTime(eventData.event.startTime)}</div>
          </div>
        </div>
        <div className='AvailableCars'>
          <div className='TitleTrip'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' alignContent='right' >
              {trimTo(eventData.event.name, 28)}
              <MoreVertIcon onClick={() => navigate(`/team/${teamId}/event/${eventData.event.id}`)} />
            </Stack>
          </div>
          <div className='LocationEvent'>
            <div className='IconLocation'>
              <LocationOnIcon sx={{color:'rgba(123, 5, 250)'}} />
              <div className='Location'>{eventData.event.location?.name}</div>
            </div>
          </div>
          <div className=''>
            {eventData.rides.slice(0, 1).map(ride => (
              <div className='RideInfo' key={ride.id}>
                <div className='RideIcons'>
                  {eventData.rides.map(() => (
                    <DirectionsCar />
                  ))}
                </div>
              </div>
            ))}
            <div>
              {noRides && <div className='NoRides'>Aucun ride disponible...</div>}
              {!noRides && <div className={`SeatsInfo ${availableSeats > 0 ? 'Available' : 'Full'}`}>
                {availableSeats > 0 ? `${availableSeats} place${(availableSeats > 1 ? 's' : '')} disponible${(availableSeats > 1 ? 's' : '')}` : 'COMPLET'}
              </div>}
            </div>
          </div>
          <div className='EventCardButtons'>
            <Stack direction='column' justifyContent={'center'} alignContent='center' alignItems='center' spacing={1} lineHeight={'0.8rem'}>
              {(!noRides && !!availableSeats) &&
                <Button onClick={() => goToRideBooking()} disabled={(iAmADriver) || ((eventData?.rides?.length || 0) === 0)} variant="contained" color='primary' sx={{ width: '80%' }} >Je réserve un Ride</Button>}
              <Button onClick={() => applyAsDriver()} disabled={iAmADriver} variant="contained" color='secondary' sx={{ width: '80%' }} >{'Je propose un Ride!'}</Button>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}
