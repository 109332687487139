import { Stack } from "@mui/material";
import { getMonthPeriodFriendly } from "../../helpers/date-helpers";
import { IMonthAndYear } from "../../models/IMonthAndYear";
import { useCallback, useMemo } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export interface IMonthSelectorProps {
    minPeriod?: IMonthAndYear;
    maxPeriod?: IMonthAndYear;
    selectedPeriod: IMonthAndYear;
    setSelectedPeriod: (period: IMonthAndYear) => void;
}

export const MonthSelector = (props: IMonthSelectorProps) => {
    const { selectedPeriod, setSelectedPeriod, maxPeriod, minPeriod } = props;

    const previousMonth = useMemo(() => selectedPeriod.month === 0
        ? { month: 11, year: selectedPeriod.year - 1 }
        : { month: selectedPeriod.month - 1, year: selectedPeriod.year }, [selectedPeriod]);
    const canGoPrevious = useMemo(() => {
        if (!!minPeriod)
            return minPeriod.year < previousMonth.year || (minPeriod.year === previousMonth.year && minPeriod.month <= previousMonth.month);
        return true;
    }, [minPeriod, previousMonth]);
    const goToPreviousMonth = useCallback(() => {
        if (!canGoPrevious)
            return;

        setSelectedPeriod(previousMonth);
    }, [previousMonth, setSelectedPeriod, canGoPrevious]);

    const nextMonth = useMemo(() => selectedPeriod.month === 11
        ? { month: 0, year: selectedPeriod.year + 1 }
        : { month: selectedPeriod.month + 1, year: selectedPeriod.year }, [selectedPeriod]);
    const canGoNext = useMemo(() => {
        if (!!maxPeriod)
            return maxPeriod.year > nextMonth.year ||  (maxPeriod.year === nextMonth.year && maxPeriod.month >= nextMonth.month);

        return true;
    }, [maxPeriod, nextMonth]);
    const goToNextMonth = useCallback(() => {
        if (!canGoNext)
            return;

        setSelectedPeriod(nextMonth);
    }, [nextMonth, setSelectedPeriod, canGoNext]);

    return <div className="MonthSelect" style={{ margin: '1rem', marginTop: 0, marginBottom: 0 }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' justifyItems='center' alignContent={'center'}>
            <span className={`${(!canGoPrevious ? 'disabledWrrow' : '')}`} onClick={() => goToPreviousMonth()}>
                <ChevronLeft />
            </span>
            <h2 className="Period">{getMonthPeriodFriendly(new Date(selectedPeriod.year, selectedPeriod.month, 1))}</h2>
            <span className={`${(!canGoNext ? 'disabledWrrow' : '')}`} onClick={() => goToNextMonth()}>
                <ChevronRight />
            </span>
        </Stack>
    </div>;
}