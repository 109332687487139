import { Card, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useMemo } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { IRide } from "../../models/IRide";
import { Ride } from "../EventDetails/EventDetails";


type RideAs = 'driver' | 'passenger';


export const MyRides = () => {
  const [rideAs, setRideAs] = React.useState<RideAs>('driver');
  const { myRides, user } = useUserContext();
  const rides = useMemo<IRide[]>(() => rideAs === 'driver'
    ? myRides.filter(r => r.driverId === user.id)
    : myRides.filter(r => r.driverId !== user.id), [myRides, user, rideAs]);

  const handleRideAsChanged = (_: any, rideAs: RideAs) => setRideAs(rideAs);

  return <div className="MyRides">
    <Stack direction='row' justifyContent={'center'}>
      <ToggleButtonGroup value={rideAs} exclusive onChange={handleRideAsChanged} sx={{
        '& .MuiToggleButtonGroup-grouped': {
          borderColor: 'rgba(123, 5, 250, 0.5)', // Border color
          fontWeight: 400, // Bold font weight
          '&.Mui-selected': {
            backgroundColor: '#7B05FA', // Primary color when selected
            color: 'white', // Text color when selected
            '&:hover': {
              backgroundColor: '#7B05FA', // Primary color on hover when selected
            },
          },
          '&:not(.Mui-selected)': {
            backgroundColor: 'white', // Unselected background color
            color: 'rgba(123, 5, 250)', // Unselected text color
            '&:hover': {
              backgroundColor: 'rgba(123, 5, 250, 0.1)', // Hover background color
            },
          },
        },
      }}>
        <ToggleButton value="driver">En tant que conducteur</ToggleButton>
        <ToggleButton value="passenger">En tant que passager</ToggleButton>
      </ToggleButtonGroup>
    </Stack>

    <Stack direction='column' spacing={1} justifyContent='center' alignContent='center' alignItems='center' lineHeight='1rem' marginY={'1rem'} marginX={'1rem'} >
      {rides.map(r =>
        <Card sx={{ backgroundColor: 'white', color: 'black', width: '98%', borderRadius: '30px ' }}>
          <Ride key={r.id} ride={r} />
        </Card>)}
    </Stack>
  </div>;
};