import { useUserContext } from "../../contexts/UserContext";
import { Link } from "react-router-dom";
import { TeamJoiner } from "../TeamJoiner/TeamJoiner";
import './TeamSelector.css';
import TeamIcon from '../../assets/icons/TeamIcon.svg';
import { Avatar, Divider, Stack } from "@mui/material";
import { ITeam } from "../../models/ITeam";

interface ITeamTileProps {
    team: ITeam;
}

const TeamTile = ({ team }: ITeamTileProps) => {
    const { selectTeam } = useUserContext();

    if (!team)
        return null;

    return <Link className="TeamTile" key={`team_${team.id}`} onClick={() => selectTeam(team.id)} to={`/team/${team.id}/events`}>
        <Stack direction='row' justifyContent='space-around'
            alignItems='center'
            alignContent='center' justifyItems='center'>
            <Avatar sx={{ width: '4rem', height: '4rem' }} src={team?.avatar || TeamIcon} />
            
            
            <div className="TeamName">
                {team.name}
            </div>
           
            </Stack>
      
    </Link>;
};

export const TeamSelector = () => {
    const { isLoading, myTeams } = useUserContext();

    if (isLoading)
        return null;

    return <div className="TeamSelector">
        <h2>Mes équipes</h2>
        <div className="MyTeams">
            {myTeams.length === 0 ? (
                <div>
                    <p style={{ marginLeft: '1rem', marginRight: '1rem', fontWeight: '600' }}>Vous n'êtes dans aucune équipe pour le moment... </p>
                    <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>  Vous pouvez rejoindre une équipe en utilisant le code unique reçu par l'administrateur.</p>
                </div>
            ) : (
                <Stack direction='column' spacing={1} gap={1} justifyContent={'space-around'} alignContent={'center'} >
                    {myTeams.map(tm => <TeamTile key={tm.teamId} team={tm.team} />)}
                </Stack>
            )}
        </div>
       <Stack paddingX={'1rem'}>
        <Divider />
        </Stack>
       
        <div className="JoinTeam">
            <h2>Rejoindre une équipe</h2>
            <TeamJoiner />
        </div>
    </div>;
};