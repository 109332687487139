import { Button, Stack, TextField, Typography } from "@mui/material";
import { IUser } from "../../models/IUser";

export interface IMyInfoProps {
    user: IUser;
    hasChanges: boolean;
    onChange: (change: (user: IUser) => IUser) => void;
    onSave: () => Promise<void>;
    onDiscard: () => void;
}

export const MyInfo = ({ user, hasChanges, onChange, onSave, onDiscard }: IMyInfoProps) => {
    return <Typography>
        <Stack direction={'column'} spacing={1} >
            <TextField label='Adresse e-mail' disabled value={user.emailAddress} />
            <TextField label='Prénom' value={user.firstName} onChange={ev => onChange(prev => ({ ...prev, firstName: ev.target.value }))} />
            <TextField label='Nom' value={user.lastName} onChange={ev => onChange(prev => ({ ...prev, lastName: ev.target.value }))} />

            <Stack direction='column' spacing={1.5} alignItems={'center'} width={'100%'}>
                {hasChanges && <>
                    <Button variant="contained" color='primary' onClick={() => onSave()} sx={{ width: '90%' }} >Valider</Button>
                    <Button variant="contained" color='secondary' onClick={() => onDiscard()} sx={{ width: '90%' }}>Annuler</Button>
                </>}
            </Stack>
        </Stack>
    </Typography>;
}