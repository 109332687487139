import { format, isToday, isFuture, addHours, addMinutes, addSeconds } from "date-fns";
import { fr } from "date-fns/locale";

export const getDayShortName = (date: Date | string) => format(date, 'EEE', { locale: fr });
export const getDayNumber = (date: Date | string) => format(date, 'dd', { locale: fr });
export const getMonthShortName = (date: Date | string) => format(date, 'MMM', { locale: fr });
export const getMonthFullName = (date: Date | string) => format(date, 'MMM', { locale: fr });
export const getMonthPeriodFriendly = (date: Date | string) => format(date, 'MMM yyyy', { locale: fr });
export const getFriendlyDate = (date: Date | string) => format(date, 'dd MMM yyyy', { locale: fr });
export const getTime = (date: Date | string) => !!date ? `${format(date, 'HH', { locale: fr })}:${format(date, 'mm', { locale: fr })}` : '';

export const getDateTimeValue = (date: Date | string, time: string) => {
    date = typeof date === 'string' ? new Date(date as string) : date;
    const [hourStr, minuteStr] = time.split(':');
    date.setHours(parseInt(hourStr));
    if (minuteStr)
        date.setMinutes(parseInt(minuteStr));
    return date;
};

export const monthDiff = (d1: Date, d2: Date) => {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
};
export const isUpcoming = (date: Date | string) => isFuture(date) || isToday(date);
export const getUpcoming = (dates: Date[]) => dates.filter(d => isUpcoming(d));

export const nowPlus = (hours: number, minutes: number = 0, seconds: number = 0) => {
    let result = new Date();
    result = addHours(result, hours);
    result = addMinutes(result, minutes);
    result = addSeconds(result, seconds);
    return result;
}