import { useCallback, useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { Input, Stack, Button } from '@mui/material';
import './TeamJoiner.css';

export const TeamJoiner = () => {

    const [code, setCode] = useState<string>('');
    const { requestJoinTeam } = useUserContext();
    const callRequestJoinTeam = useCallback(async () => {
        await requestJoinTeam(code);
    }, [code, requestJoinTeam]);

    return <div className="TeamJoin">
        <p className="Text">{`Utilisez un code d'identification unique pour rejoindre une équipe`}</p>
        <Stack direction='column' spacing={2} paddingX='1.5rem' >
            <Input type='text' placeholder='Entrez votre code unique' onChange={(ev) => setCode(ev.target.value)} />
            </Stack>
            <Stack direction='row' spacing={2} marginX='1rem' marginY={'1rem'} justifyContent={'center'}>
            <Button onClick={() => callRequestJoinTeam()} variant="contained" color="primary">Rejoindre</Button>
            </Stack>
       
    </div>
};